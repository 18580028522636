<template>
  <div>
    <v-card
      class="mb-3"
      v-if="$store.getters['pageinfo/isSupportMode']"
    >

      <v-card-title>Support Zone</v-card-title>
      <render-input
        :input="fieldConfig.targetBaiberk"
        v-model="targetBaiberk"
        class="col-md-4"
      >

      </render-input>
    </v-card>
    <custom-copy-value :item="model"></custom-copy-value>

    <v-card>
      <v-card-title>
        <v-list-item>
          <v-list-item-icon>
            <v-icon right> mdi-account </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{model.lawyerName}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
      <v-card-text>

        <v-row>
          <v-col>
            <h3 class="d-inline">REF: {{ model.refNumber }}</h3>
            <v-chip
              color="primary"
              small
              class="ml-2 mt-n1"
            >
              {{model.type ? model.type.text : ""}} | {{model.installmentPayment ? model.installmentPayment.text : ""}}
            </v-chip>

            <v-chip
              color="primary"
              small
              class="ml-1 mt-n1"
              v-if="model.oa"
            >
              {{model.oa.text}}
            </v-chip>
            <ButtonDownload
              class="ml-2"
              :url="urlDownloadFile"
            ></ButtonDownload>
          </v-col>
          <v-col cols="5">
            <v-chip class="ml-5 d-inline-block teal white--text">
              {{ model.status ? model.status.text : ""}}
            </v-chip>
            <span class="ml-2 caption">
              {{
          model && model.latestDate ? `ล่าสุด ${model.latestDate} น.` : ""
          }}
            </span>

            <v-btn
              text
              color="primary"
              v-if="model.canPaid"
              @click="paid()"
            >จ่ายเงิน</v-btn>

            <v-btn
              text
              color="error"
              v-if="model.canUnPaid"
              @click="cancelPaid()"
            >ยกเลิกการจ่ายเงิน</v-btn>

            <v-btn
              text
              color="error"
              v-if="model.canDelete"
              @click="deleteBaiberk()"
            >ยกเลิกใบเบิก</v-btn>

          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>รายการ รับ</v-list-item-title>
                <v-list-item-subtitle>
                  <v-row
                    v-for="(item,index) in model.positiveSummaries"
                    :key="index"
                    class="my-1"
                  >
                    <v-col
                      cols="8"
                      class="ml-3"
                    >{{item.description}}</v-col>
                    <v-col
                      cols="3"
                      class="text-right"
                    >
                      {{item.amount | currency(2)}}
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>รายการ หัก</v-list-item-title>
                <v-list-item-subtitle>
                  <v-row
                    v-for="(item,index) in model.negativeSummaries"
                    :key="index"
                    class="my-1"
                  >
                    <v-col
                      cols="8"
                      class="ml-3"
                    >{{item.description}}</v-col>
                    <v-col
                      cols="3"
                      class="text-right"
                    >
                      {{item.amount | currency(2)}}
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <p class="font-weight-bold display-1 text-right mt-2 mr-10">รวม {{ model.grandTotal | currency(2) }}</p>
          </v-col>

        </v-row>

      </v-card-text>
    </v-card>

    <master-table
      class="mt-5"
      :columns="columns"
      :items="model.positiveTrans"
      title="รายการ <b class='blue--text'>รับ</b>"
      :showEdit="false"
      :limitPerpage="100"
    >
      <template v-slot:table-blackNumber="{ item }">
        {{ item.blackNumber }}
        <v-btn
          text
          @click="clickdMove(item)"
          class="red--text mt-n1"
          v-if="targetBaiberk && targetBaiberk.length == 36"
        >ย้าย</v-btn>
      </template>

      <template v-slot:table-custom-event-button="{ item }">

        <v-icon
          color="info"
          @click="seeDetails(item)"
        >launch</v-icon>
      </template>
    </master-table>

    <v-divider></v-divider>

    <master-table
      :columns="columns"
      :items="model.negativeTrans"
      title="รายการ <b class='blue--text'>หัก</b>"
      :showEdit="false"
      :limitPerpage="100"
    >
      <template v-slot:table-blackNumber="{ item }">
        {{ item.blackNumber }}
        <v-btn
          text
          @click="clickdMove(item)"
          class="red--text mt-n1"
          v-if="targetBaiberk"
        >ย้าย</v-btn>
      </template>

      <template v-slot:table-custom-event-button="{ item }">
        <v-icon
          color="info"
          @click="seeDetails(item)"
        >launch</v-icon>
      </template>
    </master-table>
  </div>
</template>

<script>
import MasterTable from "./master-render/master-table";
import ButtonDownload from "./../components/custom/button-download";
import { getFakeUrl, fakeAbbr } from "../_helpers/fake-url";
import { RevertType, InputType } from "../js/constants";
import { item_confirmCloseCasewithOokmaiTang } from "./case/update-status/update-status-service";

export default {
  props: {
    id: String,
  },
  components: {
    MasterTable,
    ButtonDownload,
  },
  data() {
    return {
      model: {},
      targetBaiberk: null,
      useFakeApi: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let url = `/baiberk/${this.id}`;
      // if (this.useFakeApi) {
      //   url = getFakeUrl(fakeAbbr.baiberk.get) + url;
      // }

      this.$http.get(url).then((res) => {
        this.model = res;
      });
    },
    paid() {
      let url = `/baiberk/${this.id}/paid`;
      // if (this.useFakeApi) {
      //   url = getFakeUrl(fakeAbbr.baiberk.paid) + url;
      // }

      this.$http.post(url).then((res) => {
        this.model = res;
        this.showAlertSuccess();
      });
    },
    cancelPaid() {
      if (confirm("คุณต้องการยกเลิก 'การจ่ายเงิน' นี้ใช่หรือไม่ ?")) {
        let url = `/Baiberk/${this.id}/UnPaid`;
        // if (this.useFakeApi) {
        //   url = getFakeUrl(fakeAbbr.baiberk.cancelPaid) + url;
        // }

        let payload = {
          data: [this.id],
          type: RevertType["ยกเลิกจ่ายเงินใบเบิก"].id,
        };

        this.$http.post(url, payload).then((res) => {
          this.showAlertSuccess();
          this.getData();
        });
      }
    },
    deleteBaiberk() {
      if (confirm("คุณต้องการยกเลิก 'ใบเบิก' นี้ใช่หรือไม่ ?")) {
        let url = `/Baiberk/${this.id}/Delete`;

        let payload = {
          data: [this.id],
          type: RevertType["ยกเลิกใบเบิก"].id,
        };

        this.$http.post(url, payload).then((res) => {
          this.showAlertSuccess();
          this.$router.push({ name: "baiberk-maintain" });
        });
      }
    },
    seeDetails(item) {
      let routeData = this.$router.resolve({
        name: "case-details",
        params: {
          id: item.caseId,
          currentTab: "3",
        },
      });
      window.open(routeData.href, "_blank");
    },
    clickdMove(item) {
      let url = `/support/MoveBaiberkItems/target/${this.targetBaiberk}`;
      let sourceBaiberkLines = [item.id];

      this.$http.post(url, sourceBaiberkLines).then((res) => {
        this.showAlertSuccess();
      });
    },
  },
  computed: {
    fieldConfig() {
      return {
        useFakeApi: {
          name: "useFakeApi",
          text: "useFakeApi",
          type: InputType.checkBox,
        },
        targetBaiberk: {
          name: "targetBaiberk",
          text: "Id ของใบเบิกปลายทาง",
          type: InputType.text,
        },
      };
    },
    urlDownloadFile() {
      let url = `/baiberk/${this.id}/download`;
      return url;
    },
    columns() {
      return [
        {
          name: "lawyer.text",
          text: "ทนาย",
        },
        {
          name: "type.text",
          text: "ประเภท",
        },
        {
          name: "blackNumber",
          text: "หมายเลขคดีดำ",
        },
        {
          name: "redNumber",
          text: "หมายเลขคดีแดง",
        },
        {
          name: "caseNumber",
          text: "หมายเลขเคส",
        },
        {
          name: "description",
          text: "รายละเอียด",
        },
        {
          name: "amount",
          text: "จำนวนเงิน",
          numeric: 2,
          class: "text-right",
        },
      ];
    },
  },
};
</script>
